import { ref, computed, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('center/getAll')

  const centerOptions = computed(() => store.getters['center/getSelectedItems'])
  const centerFilter = ref(null)

  if (router.currentRoute.params.id_center)
    tenantFilter.value = parseInt(router.currentRoute.id_center)

  const tableColumns = [
    { label: 'sectors.center', key: 'center.name', sortable: true },
    { label: 'sectors.title', key: 'name', sortable: true },
    { label: 'sectors.town_hall', key: 'alcaldia', sortable: true },
    { label: 'sectors.supplier', key: 'provider', sortable: true },
    { label: 'date.created_at', key: 'created', sortable: true, formatter: 'formatDateAssigned' },
    { label: 'date.updated_at', key: 'updated', sortable: true, formatter: 'formatDateAssigned' },
  ]

  if (ability.can('update', 'Sector') == true || ability.can('delete', 'Sector') == true) {
    tableColumns.splice(0, 0, { label: 'actions.name', key: 'actions' })
  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('sector/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      id_center: centerFilter.value,
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([ centerFilter, currentPage, perPage ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,

    centerOptions,
    centerFilter,

    refetchData,
  }
}
